<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="closeDialog"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="true"
                :formItems="popForms">
                <div class="fund_load_info">
                    <text>资金明细 {{fundDetailValues.length > 0 ? '' : ': 暂无数据'}}</text>
                    <button @click="fundDetail(consumerId)" v-if="!fundDetailLoadAll">加载更多</button>
                </div>
                <div class="fund_detail" v-if="fundDetailValues.length > 0">
                    <table border="1">
                        <tr>
                            <th 
                            v-for="(th, index) in fundDetailInfo" 
                            :key="index">
                            {{th.label}}
                            </th>
                        </tr>
                        <tr 
                            v-for="(tr, trIndex) in fundDetailValues" 
                            :key="trIndex">
                                <td v-for="(td, tdIndex) in fundDetailInfo" :key="tdIndex">
                                    {{tr[td.prop]}}
                                </td>
                            </tr>
                    </table>
                </div>
                </m-dialog-content>
            </a-modal>
        <m-form
            :formItems="formItems"
            @refresh="refresh" 
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :totals="total"
            :currentPage="currentPage"
            @pageChange="loadData($event)"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { isValid, consumerDetail,consumerList, consumerDetailFund } from '../../utils/api'
import { assginDataToArray, geneIndex } from '@/utils/util'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '详情', eventName: 'detail'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '彩民昵称', dataIndex: 'nickName', key: 'nickName'},
                 {title: '手机号', dataIndex: 'phone', key: 'phone'},
                 {title: '彩民ID', dataIndex: 'consumerId', key: 'consumerId'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                 {type: 'input', label: '用户ID', value: undefined},
                 {type: 'input', label: '手机号', value: undefined},
                 {type: 'button', eventName: 'loadData', label: '搜索'},
                //  {type: 'button', eventName: 'refresh', label: '手动生成订单'}
             ],
             popForms: [
                //  {label: '姓名', prop: 'name', value: '', type: 'input'},
                 {label: '昵称', prop: 'nickName', value: '', type: 'input'},
                 {label: '电话', prop: 'phone', value: '', type: 'input'},
                 {label: 'ID', prop: 'consumerId', value: '', type: 'input'},
                 {label: '身份证号码', prop: 'idCardNo', value: '', type: 'input'},
                 {label: '钱包总余额', prop: 'walletBalance', value: '', type: 'input'},
            ],
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             loading: false,
             status: 0,
             fundDetailInfo: [
                 {label: 'ID', prop: 'id', value: ''},
                 {label: '交易金额(元)', prop: 'amountText', value: ''},
                //  {label: '资金流向', prop: 'directionText', value: ''},
                 {label: '余额(元)', prop: 'balance', value: ''},
                 {label: '交易类型', prop: 'typeText', value: ''},
             ],
             fundDetailValues: [],
             fundDetailPageNum: 1,
             fundDetailPageSize: 10,
             fundDetailLoadAll: false,
             consumerId: ''
        })
         function initFundTypeText(data) {
            data.forEach(ele => {
                const t = ele.type
                ele.typeText = t == 1 ? '购票扣款'
                              :t == 2 ? '退票扣款'
                              :t == 3 ? '返奖'
                              :t == 4 ? '提现' : '提现取消'
            })
            return data
        }
        function initFundDirectionText(data) {
            data.forEach(ele => {
                const d = ele.direction 
                ele.amountText = `${d == 1 ? '+' : '-'}${ele.amount}`
            })
            return data
        }
        async function  loadData(pageNum = null) {
            try {
                state.currentPage = pageNum || state.currentPage
                state.loading = true
                const item = state.formItems
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    consumerId: item[0].value,
                    phone: item[1].value
                }
                const result = await consumerList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data.records, state.pageSize, state.currentPage)
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        async function fundDetail(consumerId) {
            try {
                const params = {
                    consumerId: consumerId,
                    pageNum: state.fundDetailPageNum,
                    pageSize: state.fundDetailPageSize
                }
                console.log(params)
                const result = await consumerDetailFund(params)
                if (isValid(result)) {
                    let data = result.data.records
                    state.fundDetailLoadAll = state.fundDetailPageSize > data.length
                    data = initFundTypeText(data)
                    data = initFundDirectionText(data)
                    if (state.fundDetailPageNum == 1) {
                        state.fundDetailValues = data
                    } else {
                        state.fundDetailValues.push(...data)
                    }
                    state.fundDetailPageNum++
                }

            } catch(e) {
                console.error(e)
            }
        }
        async function detail(event) {
            try {
                state.consumerId = event.consumerId
                state.fundDetailPageNum = 1
                fundDetail(event.consumerId)
                const result = await consumerDetail(event.consumerId)
                if (isValid(result)) {
                    console.log(result.data)
                    state.popForms = assginDataToArray(result.data, state.popForms)
                    console.log(state.popForms)
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }
       
        
        function closeDialog() {
            loadData()
            state.showDialog = false
        }
        return {
            ...toRefs(state),
            detail,
            loadData,
            closeDialog,
            fundDetail
        }
    },
}
</script>
<style lang='scss' scoped>
.fund_load_info {
    display: flex;
    justify-content: space-between;
    text {
        font-weight: 700;
    }
    button {
        color: blue;
        border: solid blue 1px;
        padding: 0px 15px;
        border-radius: 15px;
    }
}
.fund_detail {
    height: 220px;
    overflow: auto;
    position: relative;
    table {
        width: 100% !important;
    }
    td {
        width: 20%;
    }
}
</style>